import modal from './modal';
import settings from '../settings';

export default {
  initMenuButton() {
    $('#chat-mn-btn').click(function() {
      $('#chat-fadeout').stop().fadeIn(450);
      $('#chat-mn').stop().fadeIn(300);
    });
    $('#attachments-mn-btn').click(function() {
      $('#chat-fadeout').stop().fadeIn(450);
      $('#attachments-mn').stop().fadeIn(300);
    });
    $('body').mouseup(function(e) {
      if ($('html').hasClass('html-mobile-opened')) {
        if (!$(e.target).closest('#mn-holder').length) {
          $('#mn-holder .close').click();
        }
      }
      if ($('#chat-fadeout').css('display') == 'block') {
        if (!$(e.target).closest('#chat-mn').length) {
          $('#chat-fadeout').stop().fadeOut(450);
          $('#chat-mn').stop().fadeOut(300);
          if (settings.__isMobile) $('#attachments-mn').stop().fadeOut(300);
        }
      }
      if ($('.select.opened').length) {
        if (!$(e.target).closest('.select').length) {
          $('.select.opened').find('.input-holder').click();
        }
      }
    });
  },
  initModalButtons() {
    $('.js-modal-link')
      .click(function (e) {
        e.preventDefault();
        modal.openModal("modal-upload-photo");
      });
  },
}
